<template>
  <div>
    <div
      v-if="show"
      class="overlay fixed top-0 right-0 bottom-0 left-0 bg-black z-modal bg-opacity-80"
      @click="$emit('close')"
    ></div>
    <div
      class="transition duration-300 overflow-x-scroll ease-in-out fixed transform right-0 top-0 w-320 md:w-420 h-screen bg-white z-modal"
      :class="{
        'translate-x-0': show,
        'translate-x-320 md:translate-x-420': !show,
      }"
    >
      <ClientOnly>
        <div class="p-24 border-b border-black flex items-center">
          <fa
            :icon="['fal', 'times']"
            class="sub-heading-md-text cursor-pointer"
            @click="$emit('close')"
          ></fa>
          <p class="sub-heading-md-text ml-24">
            {{ $lang('productListingResources', 'filter') }}
          </p>
          <p class="base-text cursor-pointer ml-auto" @click="$emit('reset')">
            {{ $lang('productListingResources', 'resetFilters') }}
          </p>
        </div>
      </ClientOnly>
      <div
        v-for="(filter, idx) in sortedFilters"
        :key="filter.id"
      >
        <div
          v-if="filter.values && filter.values.length > 1"
          class="p-16 md:p-20"
          :class="{ 'border-b border-black': idx + 1 !== sortedFilters.length }"
        >
          <p class="text-base font-black leading-base font-dinPro">
            {{ filter.displayName }}
          </p>
          <div class="flex flex-wrap mt-12">
            <div
              v-for="option in filter.values"
              :key="option.id"
              class="filter-value transition border duration-300 ease-in-out cursor-pointer py-8 px-20 rounded-sm base-medium-text mr-12 mb-12"
              :class="{
                'border-black selected': option.isSelected,
                'bg-filterbg border-transparent': !option.isSelected,
              }"
              @click="$emit('toggleFilterSelected', option)"
            >
              {{ option.displayName }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-full p-24">
        <Button
          :is-block="true"
          :size="Sizes.md"
          :text="$lang('productListingResources', 'showResults')"
          class="mt-12 mb-112"
          @click="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import Button from './globals/Button.vue';
import type { IFilter } from '~/models/api-types';
import { Sizes } from '~/constants/button-layouts';
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    Button,
  },
  props: {
    filters: {
      type: Array as PropType<IFilter[]>,
      required: false,
      default: [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Sizes,
    };
  },
  computed: {
    sortedFilters(): IFilter[] {
    
    return this.filters.map(filter => {
        const filteredValues = filter.values.filter(value => value.displayName !== null);
        filteredValues.sort((a, b) => {
          if (a.displayName < b.displayName) return -1;
          if (a.displayName > b.displayName) return 1;
          return 0;
        });
        return {
          ...filter,
          values: filteredValues
        };
      });
    }
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add('locked');
      } else {
        document.body.classList.remove('locked');
      }
    },
  },
  methods: {},
});
</script>
<style scoped>
.filter-value:hover:not(.selected) {
  @apply bg-grey500;
}
</style>
